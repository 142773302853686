import { graphql } from "gatsby";
import * as React from "react";
import Layout from "../components/layout";
import ColumnWrapperWithBg from "../components/organisms/ColumnWrapperWithBg";
import DataboutTools from "../components/organisms/DataboutTools";
import { Header } from "../components/organisms/Header";
import SEO from "../components/seo";
import NeedMore from "../components/organisms/NeedMore";
import ToolsBackground from "../images/tools-background.jpg";
import ColumnItemWithBg, { ColumnItemWithBgType } from "../components/molecules/ColumnItemWithBg";
import PageContent from "../components/organisms/PageContent";
import styled from "styled-components";
//import ClientsCarousel from "../components/organisms/ClientsCarousel";
import lisImage from '../images/lis-page/lis.svg';
// import sourceImage from '../images/source.svg';
import harmonyImage from '../images/harmony.svg';
import { useTranslation } from "gatsby-plugin-react-i18next";
import { WpPost } from "../shared/wp-post-utils";
import { AboutType } from "./uslugi/transport";

export type NewestNewsType = {
		allWpPost: {
			nodes: [
				{
					title: string;
					dateGmt: string;
					uri: string;
					content: string;
					language: string;
					featuredImage: {
						node: {
							sourceUrl: string;
						}
					}
				}
			]
		}
}

type ToolsType = AboutType & {
	data: {
	  parent: WpPost & {
		tools: {
		  title: string;
		  subtitle: string;
		  description: string;
		  lisDescription: string;
		  harmonyDescription: string;
		  servicesDescription: string;
		  servicesSubtitle: string;
		  servicesTitle: string;
		}
	  }
	}
  }


const ToolsDesktop = styled.div`
	display: flex;
	justify-content: flex-end;
	
	@media (max-width: 767.98px) {
		display: none;
    }
	
    @media (min-width: 768px) and (max-width: 991.98px) {
		display: none;
    }
	
    @media (min-width: 992px) and (max-width: 1199.98px) { 
		display: none;
    }
	`;

const ToolsMobile = styled.div`
	display: none;
	
	@media (max-width: 767.98px) {
		display: block;
    }
	
    @media (min-width: 768px) and (max-width: 991.98px) {
		display: block;
    }
	
	@media (min-width: 992px) and (max-width: 1199.98px) { 
		display: block;
    }
	`;

const ToolsPage: React.FC<ToolsType> = ({data}) => {
	const {t} = useTranslation();
	const {
		title,
		subtitle,
		description,
		lisDescription,
		harmonyDescription,
		servicesDescription,
		servicesSubtitle,
		servicesTitle } = data.parent.tools

	const ToolsChildren: ColumnItemWithBgType[] = [
		{
			name: t('toolsItemName1'),
			description: lisDescription,
			image: lisImage,
			link: "/tools/lis"
		},
		// {
		// 	name: t('toolsItemName2'),
		// 	description: t('toolsItemDescription2'),
		// 	image: sourceImage,
		// 	link: "/tools/source"
		// },
		{
			name: t('toolsItemName3'),
			description: harmonyDescription,
			image: harmonyImage,
			link: "/tools/harmony"
		},
	]

	return (
		<Layout>
			<SEO title={title} description={description} />
			<Header 
				title={title}
				subTitle={subtitle}
				description={description}
				isBackgroundImage={true}
				backgroundImage={ToolsBackground}
				isDiffrentBackgroundImageForMobile={true}
				isToolsHeader={true}
				headerImage={
					<ToolsDesktop>
						{ToolsChildren.map((item, index) => 
							<ColumnItemWithBg key={index} image={item.image} name={item.name} description={item.description} link={item.link} size="short"/>
						)}
					</ToolsDesktop> 
				}
			/>
		
	<PageContent>
		<ToolsMobile>
		<ColumnWrapperWithBg itemsArr={ToolsChildren} size="long" />
		</ToolsMobile>
		{/* <ClientsCarousel/> */}
		//<DataboutTools title={servicesTitle} subTitle={servicesSubtitle} description={servicesDescription} link="/uslugi"/>
		<NeedMore/>
	</PageContent>
		</Layout>
	)
}

export const pageQuery = graphql`
query($language: String!) {
	parent: wpPage(slug: {in: ["tools", "tools-2"]}, language: {slug: {eq: $language}}) {
		content
		title
		...Tools
	}
  locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
}`

export default ToolsPage
